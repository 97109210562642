import { useEffect } from "react";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { getAccount, userTimezones } from "../http/API";
import { useSysData } from "../common/hook";
import { useSelector } from "react-redux";
import {
	isAuthenticated,
	setTimezoneState,
	setReady,
	setFilloutDietForm,
	requiredInfoComplete
} from "../store/auth/sliceUser";
import { useDispatch } from "react-redux";
import MainNav from "./components/nav";
import HttpErrorContent from "./components/HttpErrorContent";
import UpdateTimezone from "./components/UpdateTimezone";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import rootData from "../types/RootData";
import { useNavigate } from "react-router-dom";
import store from "../store/index";
import { clearHttpState } from "../store/http/sliceHttp";

const PageWrapper = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const rootState = useSelector((state: rootData) => state);
	const { isFetching } = rootState.user;
	const { timezones } = rootState.user;
	const sysNav = useSysData("Menu");
	const isLogin = useSelector(isAuthenticated);
	const isInfoComplete = useSelector(requiredInfoComplete);
	const checkUserState = async (): Promise<void> => {
		// (!timezones || timezones.length === 0) && dispatch(userTimezones())
		let accountData:{ diet: any; user_data: { timezoneId: string; }; };
		// let timezoneData: Array<ITimeZone> | undefined;
		try {
			accountData = (await getAccount()).data;
			if (accountData && accountData.diet) {
				dispatch(setFilloutDietForm(true));
			}
			if (
				accountData &&
				accountData.user_data &&
				accountData.user_data.timezoneId
			) {
				if (accountData.user_data.timezoneId) {
					const matchId =
						timezones &&
						timezones.filter(
							({ id }) => id === accountData.user_data.timezoneId
						);
					if (matchId && matchId.length > 0) {
						console.log("设置时间了");
						dispatch(setTimezoneState(true));
						if (!accountData.diet) {
							navigate("/DietQuestionnaire");
						}
					} else {
						console.log("没有设置时间");
						dispatch(setTimezoneState(false));
					}
					dispatch(setReady(true));
				}
			}
		} catch(err) {
			console.log('err: ', err)
		}
	};

	const init = async () => {
		(!timezones || timezones.length === 0) && dispatch(userTimezones());
	}

	useEffect(() => {
		if (isLogin && !isInfoComplete) {
			init()
		}
	}, [isLogin]);

	useEffect(() => {
		timezones && checkUserState();
	}, [timezones]);

	useEffect(() => {
		store.dispatch(clearHttpState());
	},[]);

	return (
		<>
			{isLogin && sysNav && <MainNav menu={sysNav} />}
			<Outlet />
			{isLogin &&
				!isInfoComplete &&
				timezones &&
				timezones.length > 0 && (
					<UpdateTimezone timezones={timezones} />
				)}

			<HttpErrorContent />
			<Backdrop
				sx={{
					zIndex: "1000",
					color: "#fff",
				}}
				open={isFetching}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default PageWrapper;

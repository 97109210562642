import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getApiV1Xs10Recipes} from "../http/API";
import { SliceActionType } from "../types/Store"

export type WeeksType = { orderId: number, title: string }
export type ItemContent = {
	content: string,
	date: string
	diets: Array<{ title: string }>
	id: string
	image: {
		url: string
	}
	oldWordpressId: string
	time: { title: string }
	title: string
	weeks: WeeksType[]
}
export type RecipiesLists = {
	lists: Array<ItemContent>,
	loading: boolean,
	searchLists: Array<ItemContent>,
};
const initialState: RecipiesLists = {
	lists: [],
	loading: true,
	searchLists: []
}
export const ListsSlice = createSlice({
	name: 'recipiesLists',
	initialState,
	reducers: {
		setSearchLists: (state, { payload }: PayloadAction<Array<ItemContent>>) => {
			// console.log('search action: ', action)
			state.searchLists = [...payload]
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getApiV1Xs10Recipes.fulfilled, (state, action: SliceActionType) => {
			console.log('action: ', action)
			state.lists = [...action.payload.data as Array<ItemContent>];
			state.loading = false
		})
	}
})
export const {setSearchLists} = ListsSlice.actions
export default ListsSlice;

import {createSlice} from '@reduxjs/toolkit'
import {getApiV1Xs10WeekStore} from "../http/API";

export type TWeekLists = {
	weekLists: {[key:string]:Array<Program.WeekDetailsType>},
};
const initialState: TWeekLists = {
	weekLists: {},
}
export const ListsSlice = createSlice({
	name: 'weekLists',
	initialState,
	reducers: {

	},
	extraReducers: (builder) => {
		builder.addCase(getApiV1Xs10WeekStore.fulfilled, (state, action) => {
			state.weekLists = Object.assign(state.weekLists,action.payload)
		})
	}
})
export default ListsSlice;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import rootData from "../../types/RootData";
import { clearHttpState } from "../../store/http/sliceHttp";
import { useSysData } from "../../common/hook";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BasicModal from "../components/Modal";

const ModalContent = styled("p")({
	color: "#737373",
	fontSize: "16px",
	lineHeight: "140%",
});

const AlertContent = styled("div")({
	"& ": {
		a: {
			color: "#1976d2",
		},
	},
});

const HttpErrorContent = () => {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const sysMenu = useSysData("Menu");
	const rootState = useSelector((state: rootData) => state);
	const dispatch = useDispatch();
	const sysHome = useSysData("HOME");
	const { code } = rootState.http;
	const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

	// console.log('code: ', code)

	// useEffect(() => {
	// 	dispatch(clearHttpState());
	// }, []);

	useEffect(() => {
		if (code === "504" || code === '500') {
			setSnackbarOpen(true);
		} else if(code === '401') {
			setModalOpen(true);
			dispatch(clearHttpState());
		}
	}, [code]);

	const SnackbarHandleClose = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarOpen(false);
		dispatch(clearHttpState());
	};

	const SnackbarContentRender = () => {
		let content: JSX.Element | undefined;
		if (code === "504" || code === '500') {
			content = (
				<div style={{ position: "relative" }}>
					<Alert severity="error">
						<AlertContent
							dangerouslySetInnerHTML={{
								__html: `${sysHome.Connection_Err}`,
							}}
						/>
					</Alert>
					<IconButton
						style={{ position: "absolute", right: "0", top: "6px" }}
						size="small"
						aria-label="close"
						color="inherit"
						onClick={SnackbarHandleClose}
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>
			);
		} 
		return content;
	};

	return (
		<>
			<Snackbar
				sx={{
					"& .MuiPaper-root": {
						padding: "0",
						"& ": {
							".MuiSnackbarContent-message": {
								display: "none",
							},
							".MuiSnackbarContent-action": {
								margin: "0",
								padding: "0",
							},
							".MuiAlert-icon": {
								marginTop: "5px",
								marginLeft: "12px",
								marginRight: "10px",
							},
							".MuiAlert-message": {
								padding: "12px 22px 12px 0px",
							},
						},
					},
				}}
				open={snackbarOpen}
				autoHideDuration={null}
				onClose={SnackbarHandleClose}
			>
				{SnackbarContentRender()}
			</Snackbar>
			<BasicModal
				open={modalOpen}
				setOpen={setModalOpen}
				buttonProps={{
					ok: {
						text: sysMenu.Cancel,
						variant: "text",
					},
				}}
			>
				<div>
					<h3>{sysMenu.Error_title}</h3>
					<ModalContent>{sysMenu.NetworkError}</ModalContent>
				</div>
			</BasicModal>
		</>
	);
};

export default HttpErrorContent;

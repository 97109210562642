import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userLoginRequest, userTimezones } from "../../http/API";
import { typeUserState } from "../../types/RootData";
import type { RootState } from "../../index";
import { SliceActionType } from "../../types/Store";
import { ITimeZone } from "../../types/MyAccountData";

const initState: typeUserState = {
	email: "",
	password: "",
	isFetching: false,
	isSuccess: false,
	requiredInfoComplete: false,
	filloutDietForm: false,
	responseError: false,
	errorMessage: "",
	token: "",
	hasTimezone: true,
	timezones: undefined,
	isTimeOut: false,
	// queryAgain:[]
};

const sliceUser = createSlice({
	name: "reducer",
	initialState: initState,
	reducers: {
		setFetching(state: any, { payload }: PayloadAction<boolean>) {
			// console.log("from setFetching payload: ", payload);
			state.isFetching = payload;
			// state.responseError = false;
		},
		setResponseError(state: any, { payload }: PayloadAction<boolean>) {
			state.isFetching = false;
			state.responseError = payload;
		},
		setTimezoneState(state: any, { payload }: PayloadAction<boolean>) {
			state.hasTimezone = payload;
		},
		setReady(state: any, { payload }: any) {
			state.requiredInfoComplete = payload;
		},
		setFilloutDietForm(state: any, { payload }: any) {
			state.filloutDietForm = payload;
		},
		setTimezones(state: any, { payload }: PayloadAction<ITimeZone[]>) {
			state.timezones = payload;
		},
		logOut(state, { payload }: PayloadAction<boolean>) {
			state.isSuccess = false;
			state.requiredInfoComplete = false;
			state.token = "";
			state.hasTimezone = true;
			state.filloutDietForm = false;
			// state.timezone = [];
			state.timezones = undefined;
			// console.log("payload: ", payload);
			const forceLogout = payload ? payload.toString() : "false";
			sessionStorage.setItem("forceLogout", forceLogout);
		},
		setIsTimeOut(state, { payload }: PayloadAction<boolean>) {
			state.isTimeOut = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				userLoginRequest.fulfilled,
				(state: any, action: SliceActionType) => {
					// console.log("login payload: ", action.payload);
					if (action && action.payload) {
						const { access_token: accessToken } = action.payload;
						state.isSuccess = true;
						state.token = accessToken;
						// state.isFetching = false;
					}
					// console.log("action in addCase: ", action);
					return state;
				}
			)
			.addCase(
				userLoginRequest.rejected,
				(state: any, action: SliceActionType) => {
					// console.log("login payload: ", action.payload);
					if (action) {
						// console.log("action in reject userLoginRequest:", action);
						state.isSuccess = false;
						// state.isFetching = false;
						state.responseError =
							action.payload.code === "404" ? true : false;
					}
				}
			)
			.addCase(
				userTimezones.fulfilled,
				(state: typeUserState, action: SliceActionType) => {
					state.timezones = action.payload.data
				}
			);
	},
});
const { actions } = sliceUser;
export const {
	setFetching,
	setTimezoneState,
	setResponseError,
	setIsTimeOut,
	logOut,
	setReady,
	setFilloutDietForm,
	setTimezones,
} = actions;
export const isAuthenticated = (state: RootState) => state.user.isSuccess;
export const responseError = (state: RootState) => state.user.responseError;
export const hasTimezone = (state: RootState) => state.user.hasTimezone;
export const timezones = (state: RootState) => state.user.timezones;
export const token = (state: RootState) => state.user.token;
export const requiredInfoComplete = (state: RootState) =>
	state.user.requiredInfoComplete;
export const filloutDietForm = (state: RootState) => state.user.filloutDietForm;
export default sliceUser;

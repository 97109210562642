 let rootUrl, baseUrl
 export const PROD_ROOT_URL = 'https://app.xs-life.com';
 export const STG_ROOT_URL = 'https://app2.dev.xs-life.com';

 switch(process.env.REACT_APP_ENV) {
	case 'prod':
		rootUrl = PROD_ROOT_URL
		baseUrl = 'https://inf.xs10plan.com'
		break
	case 'stg':
		rootUrl = STG_ROOT_URL
		baseUrl = 'https://inf.dev.xs10plan.com'
		break
	default:
		rootUrl = 'http://localhost:4200'
		baseUrl = 'http://localhost:4200'
 }

export const ROOT_URL = rootUrl
export const BASE_URL = baseUrl
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getExerciseTrackingData} from "../http/API";
import { SliceActionType } from '../types/Store';

export type ExerciseTrackingData = {
	data: string,
};
const initialState: ExerciseTrackingData = {
	data: "",
};
export const DataSlice = createSlice({
	name: 'exerciseTrackingData',
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<string>) => {
			state.data = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getExerciseTrackingData.fulfilled, (state, action: SliceActionType) => {
			state.data = action.payload.data as string;
		})
	}
})
export const {setData} = DataSlice.actions
export default DataSlice;

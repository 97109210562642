import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import store from "../store/index"
import { setFetching, setResponseError, logOut, setIsTimeOut } from "../store/auth/sliceUser";
import { clearHttpState, setHttpState } from "../store/http/sliceHttp"
import {ROOT_URL, BASE_URL} from "./URL";

interface AxiosInstancePendingReqest {
	method: Method,
	url: string,
	controller?: AbortController
}

class HttpRequest {
	
	public requestInstance: AxiosInstance | null = null; 

	public pendingArr: AxiosInstancePendingReqest[] = [];

	public controller: AbortController | null = null;
	
	constructor(
		public baseURL: string,
	){
	}

	

	public get tool(): AxiosInstance {
		return this.requestInstance ? this.requestInstance : this.initTool()
	}

	initTool() {
		const tool = axios.create({
			baseURL: this.baseURL,
			timeout: 80000,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json; charset=utf-8"
			},
		})
		// intercept and handle the request
		tool.interceptors.request.use((config: AxiosRequestConfig) => {
			return this.requestHandle(config);
		}, (error: { message: any; config: any }) => {
			return this.requestErrorHandle(error)
		})

		// intercept and handle the response
		tool.interceptors.response.use((res: AxiosResponse) => {
			const {method, url} = res.config;
			method && url && this.clearPendingArr({method, url})
			// return to Class instance method to handle the response
			return this.responseHandle(res)
		}, (res) => this.responseErrorHandle(res) )

		this.requestInstance = tool;

		return tool
	}
	
	// push new request into pendingArr
	public pendingArrUpdate ({method, url}:AxiosInstancePendingReqest) {
		let controller = new AbortController();
		this.pendingArr.push({method, url, controller})
	}

	// find previous request and remove it from pending Arr
	public clearPendingArr = ({method, url}: AxiosInstancePendingReqest) => {
		this.pendingArr = this.pendingArr.reduce((arrTemp:AxiosInstancePendingReqest[], pendingItem:AxiosInstancePendingReqest) => {
			if(pendingItem.url!==url || pendingItem.method !== method) {
				arrTemp.push(pendingItem)
			}
			return arrTemp;
		},[])
	}

	public requestHandle(config:AxiosRequestConfig) {
		store.dispatch(clearHttpState());
		store.dispatch(setFetching(true));
		const {method, url} = config;
		const controllerRes = this.pendingArr.find((item:AxiosInstancePendingReqest) => item.url === url && item.method)
		// console.log('controllerRes: ', controllerRes)
		if(controllerRes?.controller) {
			this.controller = controllerRes.controller
			this.controller.abort()
		} else {
			this.controller = new AbortController()
			method && url && this.pendingArrUpdate({method, url})
		}
		return {...config, signal: this.controller.signal}
	}

	public requestErrorHandle(error:any) : Promise<never> {
		store.dispatch(setResponseError(true))
		console.log('request got error: ', error)
		return Promise.reject(error);

	}

	private getRequestErrorState(error: { message: any; config: any; }) {
		const codeArr = (error?.message || '').match(/[0-9]+/) || []
		const code = codeArr[0] ? codeArr[0] : ''
		const { timeout }  = error.config || {};
		return [code, timeout]
	}

	public responseHandle (res: AxiosResponse<any, any>) : (AxiosResponse<any, any> | Promise<AxiosResponse<any, any>>) {
		return res;
	}

	public responseErrorHandle (error: any) : Promise<never> {
		console.log('this.pendingArr: ', this.pendingArr)
		console.log('error: ', error)
		if(error.message === 'canceled') console.log('Duplicated request has been canceled.')

		const [code, timeout] =  this.getRequestErrorState(error)

		// store.dispatch(setFetching(false))
		store.dispatch(setHttpState({ code }))

		// timeout
		if (code === String(timeout)){
			store.dispatch(setIsTimeOut(true))
		}

		// token expired
		if (code === '401') {
			store.dispatch(setHttpState({ code }))
			store.dispatch(logOut(true));
		}

		this.pendingArr = []

		// return error and code
		return Promise.reject({
			code,
			error
		});
	}
	
	// request method

	async request<T = any, R = AxiosResponse<T, any>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
		let res;
		// const {url, method, headers, params, data, responseType} = config;
		try {
			res = await this.tool.request<T,R>(config)
		} catch(err) {
			console.log('err from http request:', err)
			store.dispatch(setFetching(false))
			return Promise.reject(err)
		}
		this.pendingArr.length === 0 && store.dispatch(setFetching(false))
		
		return res
	}

	get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {	
		return this.request({...config, url, method: 'GET'})
		// return this.tool.get<T, R>(url, config);
  	}

	post<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.request<T, R>({...config, url, data, method: 'POST'});
		// return this.tool.post<T, R>(url, data, config);
	}

	put<T = any, R = AxiosResponse<T>>(
		url: string,
		data?: T,
		config?: AxiosRequestConfig
	): Promise<R> {
		return this.request<T, R>({...config, url, data, method: 'PUT'});
		// return this.tool.put<T, R>(url, data, config);
	}
}

export class Request extends HttpRequest {
	constructor (
		public baseURL: string,
	) {
		super(baseURL)
	}
}

export class AuthRequest extends HttpRequest {

	constructor (
		public baseURL: string
	) {
		super(baseURL)
	}

	public requestHandle(config: AxiosRequestConfig) {
		const state = store.getState()
		const token: string | null = (state.user?.token) || null
		const configRes = super.requestHandle(config);
		return {
			...configRes, 
			headers: {
				...configRes.headers,
				token: token ? token : ''
			}
		}
	}
}

export const basicRequest = new Request(BASE_URL)
export const authRequest = new AuthRequest(BASE_URL);
export const authRootRequest = new AuthRequest(ROOT_URL);



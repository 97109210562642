import * as React from "react";
import { Modal, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalUnstyledTypeMap } from "@mui/base/ModalUnstyled/ModalUnstyled";
import { styled } from "@mui/styles";
import "./modal.css";
import { useMemo, useCallback } from "react";
const Div = styled("div")({
	width: "80%",
	margin: "100px auto",
	background: "#fff",
	textAlign: "center",
	minHeight: 200,
	fontSize: "16px",
	boxSizing: "border-box",
	display: "flex",
	justifyContent: "space-between",
	flexDirection: "column",
	position: "relative",
});
const Close = styled("div")({
	position: "absolute",
	right: "12px",
	top: "12px",
});
type ButtonReverse = "CENTER" | "LEFT" | "RIGHT";
type BtnProps = {
	text: string;
	onClick?: (event: any) => void;
	show?: boolean;
	variant: "text" | "outlined" | "contained";
	color?:'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',

};
type BasicModalType = ModalUnstyledTypeMap["props"] & {
	setOpen: (open: boolean) => void;
	buttonProps?: {
		ok?: BtnProps;
		cancel?: BtnProps;
	};
	bodyClassName?: string;
	footerClassName?: string;
	buttonReverse?: ButtonReverse;
	footer?: boolean | React.ReactNode | JSX.Element;
	closeIcon?: boolean;
};
const buttonReverseArray = {
	CENTER: "buttonCenter",
	LEFT: "buttonLeft",
	RIGHT: "buttonRight",
};
/**
 *
 * @constructor
 * @param props
 * @setOpen 控制弹窗是否需要显示函数
 * @open 弹窗状态
 * @children  子节点
 * @bodyClassName  内容区域样式
 * @footerClassName  按钮区域样式
 * @buttonReverse 按钮方向
 * @footer 自定义底部或许需不需要底部
 * @buttonProps 自定义按钮内容，点击事件 以及是否需要显示和按钮样式
 */

type keys = "ok" | "cancel";
const BasicModal: React.FC<BasicModalType> = (props) => {
	const {
		setOpen,
		open,
		children,
		bodyClassName,
		footerClassName,
		buttonReverse,
		footer,
		buttonProps,
		closeIcon,
		...rest
	} = props;
	const handleClose = useCallback(() => setOpen(false), [setOpen]);
	const renderFooter = useMemo(() => {
		if (buttonProps) {
			return (
				<>
					{Object.keys(buttonProps).map((key) => {
						const {
							text,
							variant,
							show = true,
							onClick,
							color='primary',
						} = buttonProps &&
						(buttonProps?.[key as keys] as BtnProps);
						return show ? (
							<Button
								variant={variant}
								onClick={onClick || handleClose}
								sx={{ mr: 2 }}
								key={key}
								color={color}
							>
								{text}
							</Button>
						) : null;
					})}
				</>
			);
		}
		return null;
	}, [buttonProps, handleClose]);
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Div>
					{closeIcon && (
						<Close onClick={handleClose}>
							<CloseIcon />
						</Close>
					)}
					<div className={`bodyClass ${bodyClassName || ""}`}>
						{children}
					</div>
					{footer !== false
						? footer || (
						<footer
							className={`defaultFooter ${
								buttonReverse
									? buttonReverseArray[buttonReverse]
									: ""
							}`}
						>
							<div
								className={`footerClass ${
									footerClassName || ""
								} `}
							>
								{renderFooter || (
									<>
										<Button
											variant="contained"
											onClick={handleClose}
											sx={{ mr: 2 }}
										>
											确定
										</Button>
										<Button
											variant="outlined"
											onClick={handleClose}
										>
											取消
										</Button>
									</>
								)}
							</div>
						</footer>
					)
						: null}
				</Div>
			</Modal>
		</div>
	);
};
BasicModal.defaultProps = {
	buttonReverse: "RIGHT",
	closeIcon: false,
};
export default BasicModal;

import { createSlice } from '@reduxjs/toolkit'
import { infoFetch } from "../../http/API"

interface typeState  {
}

const initState:typeState = {
	isFetching: false,
	isResponseError: false
}

const sliceSys = createSlice({
  name: 'reducer',
  initialState: initState,
  reducers: {
  },
  extraReducers: buidler => {
    buidler.addCase(infoFetch.fulfilled, (state:any, action:any) => {
      if(action) {
        state = {...state, ...action.payload}
      }
      // console.log('action in InfoFetch: ', action)
      return state
    })
  }
})

// export const InfoFetch = createAsyncThunk(
//   'sys/InfoFetch',
//   async({ lang } : typeGetSysData ) => {
//     let url = '/assets/i18n/'
//     switch(lang) {
//       case 'en-US':
//         url = `${url}en.json`
//         break
//       default:
//         url = `${url}zh.json`
//     }
//     try {
//         const res = await axios.get(url)
//         if(res.status === 200 && res.data) {
//           console.log('res from get func: ', res)
//           console.log('res data from get func: ', res.data)
//           return res.data
//         }
//     } catch(err) {
//       console.log('catch err: ', err)
//     }
//   }
// )

// Export the reducer, either as a default or named export
export default sliceSys
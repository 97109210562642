import { AllRoutes } from "../src/route/index";
import "./App.css";

function App() {
	return (
		<div className="App">
			<AllRoutes />
		</div>
	);
}
export default App;

import {combineReducers} from "redux";
import {
	persistStore,
	persistReducer,
} from "redux-persist";
import {configureStore} from "@reduxjs/toolkit";
import sliceUser from "../store/auth/sliceUser";
import sliceSys from "../store/sys/sliceSys";
import sliceHttp from "../store/http/sliceHttp";
import { infoFetch } from "../http/API"
import recipies from "./recipiesStore";
import weeksInfo from "./weekPageStore";
import preparationUnits from "./preparationUnitsStore";
import exerciseTrackingData from "./exerciseTrackingDataStore";
import storage from "redux-persist/lib/storage";
import session from 'redux-persist/lib/storage/session'

const persistConfig = {
	key: "root",
	storage,
	blacklist : ['http','sysInfo','recipiesInfo','preparationUnitsInfo','exerciseTrackingData','weeksInfo']
};

const httpPersistConfig = {
	key: 'http',
	storage:session,
}

const userPersistConfig = {
	key: "user",
	storage,
};

const sysInfoPersistConfig = {
	key: "sysInfo",
	storage: session,
};

const recipiesInfoPersistConfig = {
	key: "recipiesInfo",
	storage: session,
};

const preparationUnitsInfoPersistConfig = {
	key: "preparationUnitsInfo",
	storage: session,
};

const exerciseTrackingDataPersistConfig = {
	key: "exerciseTrackingData",
	storage: session,
};

const rootReducer = combineReducers({
	user: persistReducer(userPersistConfig, sliceUser.reducer),
	sysInfo: persistReducer(sysInfoPersistConfig, sliceSys.reducer),
	http: persistReducer(httpPersistConfig, sliceHttp.reducer),
	recipiesInfo: persistReducer(recipiesInfoPersistConfig, recipies.reducer),
	preparationUnitsInfo: persistReducer(preparationUnitsInfoPersistConfig, preparationUnits.reducer),
	weeksInfo:weeksInfo.reducer,
	exerciseTrackingData: persistReducer(exerciseTrackingDataPersistConfig, exerciseTrackingData.reducer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}),
});

// store.dispatch(InfoFetch({lang: getLanguage()}));
store.dispatch(infoFetch({ lang: "zh-CN" }));

export const persistor = persistStore(store);
export default store

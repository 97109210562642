import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
const paper = { width: "100%" };

interface PropsXsDrawer {
	className?: string;
	children: React.ReactNode;
	drawerOpen: boolean;
	anchor: "top" | "left" | "bottom" | "right";
	onCloseHandler: (value: boolean) => void;
}

const XsDrawer = ({
	anchor,
	className,
	children,
	drawerOpen,
	onCloseHandler,
}: PropsXsDrawer) => {
	const [openState, setOpenState] = useState(false);

	useEffect(() => {
		setOpenState(drawerOpen);
	}, [drawerOpen]);

	const CloseHandler = () => {
		setOpenState(false);
		onCloseHandler(false);
	};

	return (
		<>
			<Drawer
				className={className}
				anchor={anchor}
				open={openState}
				onClose={CloseHandler}
				style={paper}
			>
				{children}
			</Drawer>
		</>
	);
};

export default XsDrawer;

import React, { ReactNode, useMemo, useRef, useState } from "react";
import Header from "./Header";
import XsDrawer from "./XsDrawer";
import { styled } from "@mui/material/styles";
import Menu from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import Explore from "@mui/icons-material/Explore";
import BorderAll from "@mui/icons-material/BorderAll";
import Rocket from "@mui/icons-material/Rocket";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import ManageAccounts from "@mui/icons-material/ManageAccounts";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Link, useLocation } from "react-router-dom";
import { SysResItem } from "../../types/RootData";
import "./nav.css";

export type NavTypes = {
	menu: SysResItem;
};

const DrawerHeader = styled("div")({
	overflow: "hidden",
});

const DrawerHeaderText = styled("h2")({
	display: "block",
	float: "left",
	width: "100%",
	marginRight: "-90px",
	fontSize: "1em",
	textAlign: "center",
});

const IconClose = styled(Close)({
	float: "right",
	marginTop: ".7em",
	marginRight: ".5em",
	cursor: "pointer",
});

const DrawerContent = styled("div")({
	width: "100%",
	height: "100%",
});

const IconMenu = styled(Menu)({
	marginLeft: "6px",
});
type Keys =
	| "dashboard"
	| "guest"
	| "ProgramPage"
	| "RecipiesPage"
	| "UserAccount";
const MainNav = (props: NavTypes) => {
	const [openState, setOpenState] = useState(false);
	// const [topBarState, setTopBarState] = useState<string>("控制台");
	const loaction = useLocation();
	const target = loaction.pathname.replace("/", "");
	const toggleDrawer =
		(state: boolean, currentItem?: string) =>
		(event: React.MouseEvent | React.KeyboardEvent) => {
			// currentItem && setTopBarState(currentItem);
			setOpenState(state);
		};
	const CloseHandler = (isOpen: boolean) => {
		setOpenState(isOpen);
	};
	const { Menu, Dashboard, Preparation, Program, Recipies } = props.menu;
	const barName: { [key in Keys]: { name: string; icon: ReactNode } } =
		useMemo(() => {
			return {
				dashboard: {
					name: Dashboard,
					icon: <BorderAll className="menu-icon" />,
				},
				guest: {
					name: Preparation,
					icon: <Explore className="menu-icon" />,
				},
				ProgramPage: {
					name: Program,
					icon: <Rocket className="menu-icon" />,
				},
				RecipiesPage: {
					name: Recipies,
					icon: <FavoriteBorder className="menu-icon" />,
				},
				UserAccount: {
					name: props.menu["Account & Settings"],
					icon: <ManageAccounts className="menu-icon" />,
				},
			};
		}, [props.menu]);
	return (
		<Header
			icon={<IconMenu fontSize={"large"} />}
			iconClickHandler={() => {
				setOpenState(true);
			}}
			title={barName[target as Keys]?.name}
		>
			<XsDrawer
				anchor={"left"}
				className={"MenuDrawer"}
				drawerOpen={openState}
				onCloseHandler={CloseHandler}
			>
				<DrawerContent>
					<DrawerHeader>
						<DrawerHeaderText>{Menu}</DrawerHeaderText>
						<IconClose
							fontSize={"small"}
							onClick={toggleDrawer(false)}
						/>
					</DrawerHeader>
					<nav
						aria-label={`${Dashboard} ${Preparation} ${Program} ${Recipies} ${props.menu["Account & Settings"]}`}
					>
						<List className="menu-list">
							{Object.keys(barName).map((item, index) => {
								return (
									<React.Fragment key={index}>
										<ListItem disablePadding>
											<ListItemButton
												onClick={toggleDrawer(
													false,
													barName[item as Keys].name
												)}
												component={Link}
												to={`/${item}`}
											>
												{/* <ListItemButton
									onClick={toggleDrawer(false, Dashboard)}
									component={Link}
									to="/dashBoard"
								> */}
												<ListItemIcon>
													{barName[item as Keys].icon}
												</ListItemIcon>
												<ListItemText
													primary={
														barName[item as Keys]
															.name
													}
												/>
											</ListItemButton>
										</ListItem>

										<Divider />
									</React.Fragment>
								);
							})}

							{/*<ListItem disablePadding>*/}
							{/*	<ListItemButton*/}
							{/*		onClick={toggleDrawer(false, Preparation)}*/}
							{/*		component={Link}*/}
							{/*		to="/guest"*/}
							{/*	>*/}
							{/*		<ListItemIcon>*/}
							{/*			<Explore className="menu-icon" />*/}
							{/*		</ListItemIcon>*/}
							{/*		<ListItemText primary={Preparation} />*/}
							{/*	</ListItemButton>*/}
							{/*</ListItem>*/}

							{/*<Divider />*/}

							{/*<ListItem disablePadding>*/}
							{/*	<ListItemButton onClick={toggleDrawer(false, Program)}*/}
							{/*					component={Link}*/}
							{/*					to="/ProgramPage">*/}
							{/*		<ListItemIcon>*/}
							{/*			<Rocket className="menu-icon" />*/}
							{/*		</ListItemIcon>*/}
							{/*		<ListItemText primary={Program} />*/}
							{/*	</ListItemButton>*/}
							{/*</ListItem>*/}

							{/*<Divider />*/}

							{/*<ListItem disablePadding>*/}
							{/*	<ListItemButton*/}
							{/*		onClick={toggleDrawer(false, Recipies)}*/}
							{/*		component={Link}*/}
							{/*		to="/RecipiesPage"*/}
							{/*	>*/}
							{/*		<ListItemIcon>*/}
							{/*			<FavoriteBorder className="menu-icon" />*/}
							{/*		</ListItemIcon>*/}
							{/*		<ListItemText primary={Recipies} />*/}
							{/*	</ListItemButton>*/}
							{/*</ListItem>*/}

							{/*<Divider />*/}

							{/*<ListItem disablePadding>*/}
							{/*	<ListItemButton*/}
							{/*		onClick={toggleDrawer(*/}
							{/*			false,*/}
							{/*			props.menu["Account & Settings"]*/}
							{/*		)}*/}
							{/*		component={Link}*/}
							{/*		to="/UserAccount"*/}
							{/*	>*/}
							{/*		<ListItemIcon>*/}
							{/*			<ManageAccounts className="menu-icon" />*/}
							{/*		</ListItemIcon>*/}
							{/*		<ListItemText*/}
							{/*			primary={*/}
							{/*				props.menu["Account & Settings"]*/}
							{/*			}*/}
							{/*		/>*/}
							{/*	</ListItemButton>*/}
							{/*</ListItem>*/}
						</List>
					</nav>
				</DrawerContent>
			</XsDrawer>
		</Header>
	);
};
export default MainNav;

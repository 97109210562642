const zh = {
	Menu: {
		Title: "菜单",
		Menu: "菜单",
		Dashboard: "控制台",
		"Free Videos": "免费视频",
		Preparation: "如何开始",
		Program: "10周变身计划",
		Recipies: "减脂美食",
		"Timer / Countdown": "计时器",
		Countdown: "倒计时",
		Faq: "FAQ",
		FAQ: "热门问答",
		Timer: "Timer / Countdown",
		Tracking: "体型跟踪记录",
		Support: "帮助支持",
		"Account & Settings": "我的账号",
		"Before / after image": "前后对比照片",
		"Your Account": "你的账号",
		ChatJs: "ChatJs",
		"Reset workout data": "重置训练和体型数据",
		"Language settings": "语言设置",
		"Log out": "退出",
		language_select: "选择语言",
		Chinese: "简体中文",
		English: "English",
		btn_select: "选择",
		btn_cancel: "取消",
		exit_text: "exit?",
		exit_text_1: "Do you want to exit the app?",
		exit_text_2: "Exit",
		exit_text_3: "Press Again to Confirm Exit",
		Again: "加把劲儿！",
		NetworkError:
			"如果您从另一个设备上登录您的账号，那么您的账号会从先前登录的设备上自动退出登录。所以请您再次登录即可。",
		Error_title: "贴心提示",
		Cancel: "返回重试",
		back: "返回",
		uploadErrorMsg: "网络不给力哦",
	},
	HOME: {
		Buy_program: "购买程序",
		"Sign-in": "登录",
		Title: "10周拥有性感身材， \n 然后，一直保持下去。",
		"Guest-in": "随便看看",
		"Dont-registered": "还不是XS10会员？",
		Not_match: "用户名/密码不对",
		Connection_Err:
			"<span>我们的服务器目前无法使用，请稍后再试，或者联系<a href='mailto:hello@xs-life.com'>hello@xs-life.com</a>获得帮助。</span>",
		"Dont-registered_text1": "请到www.XS10zhou.com注册",
		ok: "好的",
		Loading: "进行中",
		forgot_password_text: "忘记密码了？",
		forgot_password_desc: "请发邮件告诉我：hello@xs.life ",
		forgot_password_btn: "必须的",
	},
	REGISTER: {
		Title: "Register",
		Already_have_an_XS10_Account: "已经有了XS10账号？",
		Sign_in_here: "在此登录",
		"I_confirm_XS.life": "我已阅读并同意XS.life的",
		terms_and_conditions: " 服务条款",
		and: " 和",
		billing_terms: "付费条款",
		privacy_policy: "在阅读我们的隐私政策",
		here: "这里",
		Continue: "继续",
		What_do_I_get: "您将得到的",
		You_are_now_logged_in: "登录成功啦！",
		Or_enter_your_email: "或者输入您的电子邮件",
	},
	TERM: {
		Title: "服务条款",
		Billing_terms: "付费条款",
		Chinafitter_Limited: "版权所有2020，Chinafitter Limited",
		StartMyJourney: "我要改变身材！",
		Service1: "独特激励机制，让你“不能拖延”",
		Service2: "30分钟在家快速训练",
		Service3: "新手0基础，同步跟做视频，动作简单，只需照做",
		Service4: "均衡饮食，不节食，可以吃主食和肉，家常食材",
		Service5: "无产品，无代餐，健康饮食+高效训练，科学减脂塑形",
		Service6: "自控力和激励教程，全程心理辅导",
	},
	Login: {
		Title: "登录",
		Email: "邮箱",
		Password: "密码",
		"Re-Password": "重复输入密码",
		"Sign-in": "进入XS10",
		"Password forgotten": "忘记密码了？",
		"Dont-registered": "还不是XS10会员？",
		Popup_error_username: "用户名或密码好像不对哦",
		Popup_error_btn: "我重新写",
		error_provider_password_missmatch:
			"您两次输入的密码不一致哦，请重新输入一下啦 （：",
		error_provider_password_email_not_right:
			"您输入的邮箱格式不正确哦，请重新输入一下啦",
	},
	CompletePurchase: {
		user_name: "姓名",
		Please_complete_your_profile: "请填写您的信息",
		Country: "国家",
		Timezone: "选择你的时区",
		Male: "男神",
		Female: "女神",
		Birthday: "生日",
		Wechat_account: "微信号",
		Your_XS10_start_date:
			"XS10开始日期（首次设置必须在7天后哦，进入app后可以修改到任意一天）",
		Chose_start_date: "选择计划开始日期",
		Complete_purcharse: "完成购买",
	},
	Singleworkout: {
		title: "单独动作视频",
	},
	Dashboard: {
		Title: "控制台",
		Completed: "已完成",
		"Before image": "XS10开始前照片",
		"After image": "XS10完成后照片",
		"Link CTA": "Link CTA",
		Finished_xs10: "恭喜你完成了XS10！",
		start_text_1: "距离你的XS10开始还有",
		start_text_2: "请为你的XS10做好准备",
		start_days: "天",
		start_can_change: "点",
		start_can_change_2: "修改开始日期",
		start_here: "这里",
		you_are_in: "你已经进行到第",
		week: "周",
		"more to go": "还剩",
	},
	"Before After": {
		Title: "前后对比照片",
		Tip: "万元悬赏：每个月我会选出10名完美变身战士，请务必在XS10开始前和结束后上传体型照片，即可有机会赢得万元奖金。",
		beforeImage: "之前照片",
		afterImage: "之后照片",
		"Link CTA": "Link CTA",
		SelectImage: "选择照片",
		Upload: "上传",
	},
	Changedate: {
		Title: "修改XS10开始日期",
		"Tip-one":
			"在XS10开始之前，你可以随时在这里修改开始日期，提前或延后。请注意，XS10一旦开始，开始日期将不能再被修改。",
		"Current-date": "当前的开始日期",
		"Change-date": "修改开始日期",
		before_program_starts: "",
		"Remaining days": "距离XS10开始还有",
		day: "天",
		cannotchange_title: "提示",
		cannotchange_desc: "已经开始咯，不能改哦",
		cannotchange_btn: "好的",
		cannotchange_title_2: "不能时空穿梭哦",
		cannotchange_desc_2: "修改后的日期不能在过去",
		cannotchange_btn_2: "遵命",
		success_change_title: "改好了",
		success_change_desc: "",
		success_change_btn: "我记住新日期了",
	},
	Chartjs: {
		Title: "Chartjs",
	},
	Fogotten: {
		Title: "忘记密码",
		Email: "你的邮箱",
		Submit: "发送",
		Error_title: "邮件已发送给您了",
		Error_msg: "请立即查看您的邮箱，进行下一步操作。",
		Error_btn: "没问题",
	},
	Main: {
		Title: "免费训练视频",
		"Free workout": "免费训练视频",
		"What to expect": "你将得到",
		Warmup: "热身运动",
		Cooldown: "拉伸运动",
	},
	Myaccount: {
		Title: "个人信息",
		"Change account data": "修改个人信息",
		"Date of birth": "生日",
		Gender: "性别",
		Female: "女神",
		Male: "男神",
		Street: "详细地址",
		Name: "姓名",
		City: "城市",
		Country: "国家",
		Phone: "手机",
		Email: "邮箱",
		"Recommended diet": "适合我的饮食计划",
		"Save changes": "保存修改",
		Tips: "如果你想修改你的邮箱，\n 请给我发邮件：hello@xs-life.com",
		"your street name": "地址",
		"your full name": "名字",
		"your city": "城市",
		"your phone num": "电话",
		Error_input: "输入有误",
		Error_btn: "再来一次",
		Successfully_saved: "保存成功了！",
		Successfully_btn: "哦也！",
		Your_timezone: "您的时区",
	},
	Program: {
		Title: "10周变身计划",
	},
	Recipies: {
		Title: "美食烹饪食谱",
		Morning: "丰盛早餐",
		Lunch: "营养午餐",
		Dinner: "精致晚餐",
		Type_All: "类型",
		Week_All: "周",
		dismiss: "取消",
	},
	Stopwatch: {
		Title: "计时器",
		timer: "计时器",
		countdown: "倒计时",
		Dinner: "精致晚餐",
		start: "开始",
		stop: "停止",
		reset: "重置",
	},
	Week: {
		Title: "周",
		finished_week: "计划不完成，休想点我。",
		finished_week_undo: "我点错了，还没全练完。",
		"Week 1": "第1周",
		"Week 2": "第2周",
		"Week 3": "第3周",
		"Week 4": "第4周",
		"Week 5": "第5周",
		"Week 6": "第6周",
		"Week 7": "第7周",
		"Week 8": "第8周",
		"Week 9": "第9周",
		"Week 10": "第10周",
		All: "周",
		Type: "类型",
		"High Carb": "高碳水食谱",
		Standard: "标准食谱",
	},
	DeletevideocachePage: {
		Title: "清理视频缓存",
		message:
			"为了使视频播放更流畅，视频自动通过手机缓存来播放。\n你可以随时清理缓存文件来释放更多的手机空间。",
		Currently: "当前使用的空间：",
		Delete_Button: "清理缓存文件",
		popup_desc: "成功清除多余脂肪!",
		popup_btn: "轻松多了！",
	},
	Account: {
		Title: "我的账号",
		ChangeYourData: "修改你的信息",
		ChangeStartData: "修改你的XS10开始日期",
		DeleteVideoCache: "清理视频缓存",
		EraseWorkoutData: "重置训练和体型记录",
		OpenSupportTicket: "提交问题求助",
		RestartDietQuestionnaire: "重新开始饮食推荐向导",
		"Timer/Countdown": "计时器",
		Faq: "热门问答",
		FAQ: "热门问答",
		"Before/AfterImageUpload": "前后对比照片",
		"Imprint&TOS": "服务条款",
		LOGOUT: "退出登录",
		Submit: "提交",
		Imprint: "服务条款",
		Ticket_header: "我的提问",
		YourMessage: " 我的邮箱：hello@xs-life.com \n  我的微信：gildedyouth",
	},
	EraseworkoutdataPage: {
		Title: "重置所有记录",
		message:
			"如果你想重置所有训练和体型记录，请点这里。成绩一旦重置，不能恢复，请想好了再点哦。",
		Reset_button: "重置训练和体型记录",
		alert_text:
			"所有手动输入的训练和体型记录将被删除并重置。请务必只在重新开始新的一轮XS10时使用此功能。确定继续吗？",
		alert_title: "重置训练和体型记录",
		Ok: "确定",
		Cancel: "取消",
	},
	Tracking: {
		title: "体型跟踪记录",
		Today: "今天",
		Entites: "记录",
		Delete: "删除",
		Data: "数据",
		addnew: "输入新的记录",
		weight: "体重",
		b: "胸围 (cm)",
		c: "大臂围 (cm)",
		d: "腰围 (cm)",
		e: "臀围 (cm)",
		f: "大腿围 (cm)",
		g: "小腿围 (cm)",
		h: "体脂率 (%)",
	},
	Entry: {
		Title: "体型跟踪记录",
		Weight: "体重 (kg)",
		b: "胸围 (cm)",
		c: "大臂围 (cm)",
		d: "腰围 (cm)",
		e: "臀围 (cm)",
		f: "大腿围 (cm)",
		g: "小腿围 (cm)",
		h: "体脂率 (%)",
	},
	Question: {
		disclarmer: "免责声明",
		disclarmer_text1:
			"在开始XS10之前，请务必先咨询医生，以排除身体潜在病患或不适。",
		disclarmer_text2: "如果遇到任何问题，请直接联系我。",
		disclarmer_agreement_1: "我已阅读并同意",
		disclarmer_agreement_2: "服务条款",
		disclarmer_agreement: "我已阅读并同意服务条款协议",
		disclarmer_btn_ok: "我同意",
		q1title: "我的个人情况",
		Weight: "体重 (kg)",
		Height: "身高 (cm)",
		Gender: "性别",
		Age: "年龄",
		q2title: "我希望...",
		q3title: "我是全素主义者（不吃肉、蛋、奶）吗？",
		q4title: "我的目标体重是多少？",
		q5title: "我必须要吃主食吗？",
		q6title: "我住在海外，爱吃奶酪芝士等外国食品，而且容易买到？",
		q7title: "title in en.json",
		Goal_weight: "我的目标体重(kg)",
		answer_1: "体重",
		answer_2: "减脂",
		answer_3: "少量减脂，完善体型",
		answer_4: "增肌",
		answer_5: "是的",
		answer_6: "不是",
		answer_7: "必须吃，不吃头晕",
		answer_8: "可以不吃，越吃越困",
		answer_9: "是的",
		answer_10: "不是",
		result_1: "增肌饮食计划",
		result_2: "绝对素食计划",
		result_3: "高碳水饮食计划",
		result_4: "海外饮食计划",
		result_5: "标准饮食计划",
		result_6: "轻速饮食计划",
		error_1: "输入错误",
		ok: "确定",
		dismiss: "取消",
	},
	FreeVideo: {
		Title: "免费视频",
	},
	RestartquestionnairePage: {
		title: "重新开始饮食推荐向导",
		message: "需要重置推荐结果？",
		reset: "重置",
	},
	Questionnaire: {
		Finished_Title: "推荐的饮食计划",
		form_hi: "嗨~",
		form_begin_msg:
			"为了个性化定制你的XS10，请回答以下几个小问题，以便为你推荐最适合你的饮食计划。",
		button_next: "下一页",
		button_back: "返回",
		finished_text1: "根据你的回答，我建议你使用：",
		finished_text2: "根据你设定，距离你的XS10开始还有",
		finished_days: "天",
		finished_text3: "你可以在“我的账号”里修改日期。",
		finished_text4:
			"请在XS10开始前认真观看所有说明视频和文字，以及热门问答，以便为XS10做好充分准备，在10周内获得最大化的变身效果。",
		button_finished: "完成",
	},
	Exercises: {
		Title: "训练成绩记录",
		Set: "组数",
		Reps: "重复次数",
		"Weight/Time": "重量(或时长)",
	},
	Units: {
		"track your results": "训练菜单及单独动作讲解视频",
		"Exercises & tracking": "训练菜单及单独动作讲解视频",
		"Habit building": "自控力全攻略：培养习惯，保持身材",
		"workout video": "你的训练视频",
		Workout: "训练",
		Diet: "饮食",
		Motivation: "激励",
		Coaching: "指导说明",
		"Single workout explanation": "单独动作视频",
		"Diet plans": "本周的饮食计划",
		Error_title_1: "10周变身计划已经结束",
		Error_msg_1: "训练视频不能再播放了哦",
		Error_btn_1: "原来如此",
		Error_title_2: "训练视频已经飞走了",
		Error_msg_2: "10周已经过了，训练视频不能被观看了哦",
		Error_btn_2: "原来如此",
	},
	ErrorMsg: {
		NetworkError: "网络出错",
		Your_Start:
			"您计划的开始日期必须在7天之后哦（一开始需要阅读和准备时间，进入app后可以修改到任意一天）",
		Email_Already_Exists: "您的邮箱已存在咯，请换用另外一个邮箱啦",
		Necessary_Fields_Not_Be_Blank: "请您务必填写所有的信息哦，再检查一下啦",
		Check_Box_Is_Not_Checked:
			"请确认您已经阅读并同意我们的服务条款和收费条款。",
		Start_Day_Is_Too_Early:
			"您计划的开始日期必须在7天之后哦（一开始需要阅读和准备时间，进入app后可以修改到任意一天）",
		Password_Missmatch: "您两次输入的密码不一致哦，请重新输入一下啦 （：",
		Dont_Choose_Gender: "请选择您的性别👧👦",
		Dont_Choose_Birthday: "请输入您的生日🎂",
		Dont_Choose_Start_Date: "请设定计划开始日期📅",
	},
	TimezoneModal: {
		Update: "更新时区",
		Please_up: "请更新您的时区设置。",
		W_info_text:
			"新一周的锻炼现在将根据您个人的时区设置在午夜解锁。您也可以在您的用户设置中改变时区。",
		Click_b: "点击进入下框来改变时区，然后保存设置。",
		Save_timezone: "保存时区",
	},
};
export default zh;


import { useState, useEffect } from 'react'
import rootData, { SysResItem, SysInfoProps } from '../types/RootData'
import { useSelector } from 'react-redux'

export function useSysData(prop:SysInfoProps) : SysResItem {
    const rootReduceData = useSelector((state:rootData) => state)
    const [sysData, setSysData] = useState<SysResItem | null>(null)
    useEffect(() => {
        const data = rootReduceData.sysInfo[prop]
        setSysData(data)
    }, [rootReduceData])
  return sysData ? sysData : {}
}

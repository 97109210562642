import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSysData } from "../../common/hook";
import {
	hasTimezone,
	setTimezoneState,
	filloutDietForm,
} from "../../store/auth/sliceUser";
import { getYourTimezone, modifyAccount } from "../../http/API";
import { styled } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
	MenuItem,
	Select,
	OutlinedInput,
	SelectChangeEvent,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { ITimeZone } from "../../types/MyAccountData";

const StrongDiv = styled("div")({
	fontWeight: "500",
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const UpdateTimezone = ({ timezones } : { timezones: ITimeZone[] | undefined}) => {
	const hasFilloutDietForm = useSelector(filloutDietForm);
	const userTimezone = useSelector(hasTimezone);
	const dispatch = useDispatch();
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const sysTimezoneModal = useSysData("TimezoneModal");
	const [timezone, setTimezone] = useState<MyAccount.timezoneType[]>([]);
	const [timezoneId, setTimezoneId] = useState<string>("");
	const navigate = useNavigate();

	const handleDialogClose = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		console.log("setDialogOpen");
		setDialogOpen(reason === "backdropClick" ? true : false);
	};
	const handleSelectChange = () => (event: SelectChangeEvent) => {
		console.log("handleSelectChange");
		const {
			target: { value },
		} = event as SelectChangeEvent;
		setTimezoneId(value);
	};

	const handleSubmit = async (timezoneId: string): Promise<void> => {
		const postData: Partial<MyAccount.UserInfoType> &
			MyAccount.UserInfoDataType = {
			user_data: { timezone: timezoneId },
		};
		await modifyAccount(postData.user_data as MyAccount.UserInfoDataType);
		dispatch(setTimezoneState(true));
		setDialogOpen(false);
		// return new Promise((resolve,reject) => {
		// 	dispatch(setTimezoneState(true));
		// 	setDialogOpen(false);
		// 	console.log("done");
		// 	resolve('ok');
		// })
	};

	const navigateTo = (url: string) => {
		if (!hasFilloutDietForm) {
			navigate(url);
		}
	};

	const [initCancel, setInitCancel] = useState<boolean>(false)

	const initTime = async () => {

		const result = await getYourTimezone("2021-08-23T14:17:33+0200");

		const newTimezone = (timezones as MyAccount.timezoneType[]).map(
			(item) => {
				const findName = (result.data as MyAccount.translationType[]).find(
					(translate) => translate.id.split("_")[1] === item.title
				);
				return {
					...item,
					trasnlatedName: `${
						findName ? findName.value : item.title
					} (${
						item?.timeShift < 10 && item?.timeShift > -10
							? `${
									item?.timeShift > 0
										? `0${item?.timeShift}`
										: `-0${item?.timeShift * -1}`
							  }:00`
							: `${item?.timeShift}:00`
					})`,
				};
			}
		);
		setTimezone(newTimezone);
	};

	useEffect(() => {
		// fix the React memory leak warning
		//Warning: Can't perform a React state update on an unmounted component.
		!initCancel && initTime()
		return () => {
			setInitCancel(true);
		};
	}, []);

	useEffect(() => {
		if (!userTimezone) setDialogOpen(true);
	}, [userTimezone]);

	return (
		<>
			<Dialog
				open={dialogOpen}
				onClose={handleDialogClose}
				disableEscapeKeyDown={true}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{sysTimezoneModal.Update}
				</DialogTitle>
				<DialogContent>
					<div id="alert-dialog-description">
						<StrongDiv>{sysTimezoneModal.Please_up}</StrongDiv>
						<div>{sysTimezoneModal.W_info_text}</div>
						<p>{sysTimezoneModal.Click_b}</p>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
								{sysTimezoneModal.Update}
							</InputLabel>
							<Select
								labelId="demo-multiple-name-label"
								id="demo-multiple-name"
								onChange={handleSelectChange()}
								value={timezoneId}
								input={<OutlinedInput label="Name" />}
								MenuProps={MenuProps}
							>
								{timezone.map((item) => (
									<MenuItem key={item.id} value={item.id}>
										{item.trasnlatedName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl sx={{ marginTop: "16px" }} fullWidth>
							<Button
								disabled={timezoneId ? false : true}
								onClick={async () => {
									if (timezoneId) {
										await handleSubmit(timezoneId);
										navigateTo("/DietQuestionnaire");
										// console.log('res from handle submit: ', res)
										// if(res === 'ok') {
										// 	navigateTo("/DietQuestionnaire");
										// }
									}
								}}
								fullWidth
								type="submit"
								variant="contained"
							>
								{sysTimezoneModal.Save_timezone}
							</Button>
						</FormControl>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default UpdateTimezone;

import { lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../store/auth/sliceUser";
import PageWrapper from "../pages/PageWrapper";
import RoutesConfig, { RouteConfigItem } from "./config";

const Login = lazy(() => import("../pages/Login"));

export const AllRoutes = () => {
	const isLogin = useSelector(isAuthenticated);
	const location = useLocation();

	const PrivateRoute = ({
		children,
	}: {
		children: JSX.Element;
	}): JSX.Element => {
		return isLogin ? (
			children
		) : (
			<Navigate to="/home" state={{ from: location }} replace />
		);
	};

	const RenderElement = (
		ele: JSX.Element,
		fallback?: React.SuspenseProps["fallback"]
	): JSX.Element => {
		return <Suspense fallback={fallback ? fallback : null}>{ele}</Suspense>;
	};

	const SuspenseElement = (
		ele: JSX.Element,
		isPrivate?: boolean,
		fallback?: React.SuspenseProps["fallback"]
	): JSX.Element => {
		return isPrivate ? (
			<PrivateRoute>{RenderElement(ele, fallback)}</PrivateRoute>
		) : (
			RenderElement(ele, fallback)
		);
	};

	const RenderRoute = (
		RoutesConfig: Array<RouteConfigItem>
	): JSX.Element[] => {
		return RoutesConfig.map(
			({ path, element, isPrivate, fallback, children }, index) => {
				return (
					<Route
						key={index}
						path={path}
						element={
							path === "home" ? (
								
									<Suspense fallback={null}>
										<Login />
									</Suspense>
								
							) : (
								SuspenseElement(
									element,
									isPrivate === undefined ? true : isPrivate,
									fallback
								)
							)
						}
					>
						{children &&
							children.length > 0 &&
							RenderRoute(children)}
					</Route>
				);
			}
		);
	};

	return (
		<Routes>
			<Route element={<PageWrapper />}>
				{RenderRoute(RoutesConfig)}
			</Route>
		</Routes>
	);
};

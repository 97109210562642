import React, { useCallback, useMemo } from "react";
import { styled } from "@mui/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";

type HeaderProps = {
	title: string | React.ReactNode;
	path?: string;
	icon?: React.ReactNode | JSX.Element;
	style?: React.CSSProperties;
	className?: string;
	iconClickHandler?: () => void;
	children?: React.ReactNode;
};

const HeaderEle = styled("header")({
	width: "100vw",
	display: "flex",
	padding: "0 4px",
	minHeight: "50px",
	alignItems: "center",
	position: "fixed",
	top: 0,
	left: 0,
	backgroundColor: "#fff",
	zIndex: 99,
});
const Title = styled("div")({
	display: "block",
	width: "100%",
	textAlign: "center",
	paddingRight: "28px",
	fontSize: "18px",
	fontWeight: "600",
});
/**
 *
 * @param title 标题
 * @param iconClickHandler 自定义放回函数
 * @param icon  自定义返回图标
 * @param path 自定义返回路径
 * @param children 子节点
 * @param resetParams 其余参数
 * @constructor
 */
const typeArray = ["string", "boolean", "number"];
const Header: React.FC<HeaderProps> = ({
	title,
	path,
	icon,
	iconClickHandler,
	className,
	children,
	...resetParams
}: HeaderProps) => {
	const navigate = useNavigate();
	const ClickHandler = useCallback(() => {
		if (iconClickHandler) {
			iconClickHandler();
			return;
		}
		path ? navigate(path) : navigate(-1);
	}, [iconClickHandler, path]);
	const Icon = useMemo(() => {
		if (!icon) return null;
		if (typeArray.includes(typeof icon)) {
			return icon;
		}
		try {
			// @ts-ignore
			const { onClick } = "props" in icon ? icon.props : {};
			return React.cloneElement(icon as JSX.Element, {
				onClick: onClick || ClickHandler,
			});
		} catch (e: any) {
			throw TypeError(e);
		}
	}, [ClickHandler, icon]);
	return (
		<HeaderEle {...resetParams}>
			{Icon ? Icon : <ArrowBackIosNewIcon onClick={ClickHandler} />}
			<Title>{title}</Title>
			<Divider
				style={{
					position: "absolute",
					width: "100%",
					left: 0,
					bottom: 0,
				}}
			/>
			{children ? children : null}
		</HeaderEle>
	);
};
export default Header;

import { lazy } from "react";

const NotFound = lazy(() => import("../pages/Error/NotFound"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Guest = lazy(() => import("../pages/Guest"));
const Recipies = lazy(() => import("../pages/Recipies"));
const UserAccount = lazy(() => import("../pages/UserAccount"));
const EraseworkoutdataPage = lazy(() => import("../pages/Eraseworkoutdata"));
const ProgramPage = lazy(() => import("../pages/Program"));
const DietQuestionnaire = lazy(() => import("../pages/DietQuestionnaire"));
const MyaccountPage = lazy(() => import("../pages/Myaccount"));
const ChangedatePage = lazy(() => import("../pages/Changedate"));
const WeekPage = lazy(() => import("../pages/WeekPage"));
const UnitsPage = lazy(() => import("../pages/UnitsPage"));
const TrackingPage = lazy(() => import("../pages/TrackingPage"));
const BeforeAfter = lazy(() => import("../pages/BeforeAfter"));

export interface RouteConfigItem {
	path: string;
	element: JSX.Element;
	isPrivate?: boolean;
	fallback?: React.SuspenseProps["fallback"];
	children?: Array<RouteConfigItem>;
}

const RoutesConfig: Array<RouteConfigItem> = [
	{
		path: "*",
		element: <NotFound />,
		isPrivate: false,
	},
	{
		path: "/",
		element: <Dashboard />,
	},
	{
		path: "home",
		element: <Dashboard />,
	},
	{
		path: "dashboard",
		element: <Dashboard />,
	},
	{
		path: "guest",
		element: <Guest />,
	},
	{
		path: "RecipiesPage",
		element: <Recipies />,
	},
	{
		path: "UserAccount",
		element: <UserAccount />,
	},
	{
		path: "ProgramPage",
		element: <ProgramPage />,
	},
	{
		path: "EraseworkoutdataPage",
		element: <EraseworkoutdataPage />,
	},
	{
		path: "WeekPage/:info",
		element: <WeekPage />,
	},
	{
		path: "UnitsPage/:query",
		element: <UnitsPage />,
	},
	{
		path: "TrackingPage/:query",
		element: <TrackingPage />,
	},
	{
		path: "ChangedatePage",
		element: <ChangedatePage />,
	},
	{
		path: "MyaccountPage",
		element: <MyaccountPage />,
	},
	{
		path: "DietQuestionnaire",
		element: <DietQuestionnaire />,
	},
	{
		path: "BeforeAfter",
		element: <BeforeAfter />,
	},
];

export default RoutesConfig;
import { basicRequest, authRequest, authRootRequest } from "./index";
import {createAsyncThunk} from "@reduxjs/toolkit";
import zh from "../locales/zh"
import base64 from "base-64";
import {AxiosResponse} from "axios";
import {FormLoginData} from "../types/Login";
import {typeGetSysData} from "../types/Sys";

/* handling error */
const ErrorHandle = (err:any, rejectWithValue:Function) => {
	let code
	if(err.error.code) {
		code = err.error.code
		return rejectWithValue({code})
	} else {
		return rejectWithValue(err)
	}
}

/* get sys language file */
export const infoFetch = createAsyncThunk(
	'sys/InfoFetch',
	async ({lang}: typeGetSysData) => {
		let url = `./assets/i18n/`
		// let url = `${ROOT_URL}/assets/i18n/`
		switch (lang) {
			case 'en-US':
				url = `${url}en.json`
				break
			default:
				url = `${url}zh.json`
		}
		try {
			if (zh) {
				// console.log('res from get func: ', res)
				// console.log('res data from get func: ', res.data)
				return zh
			}
		} catch (err) {
			console.log('catch err: ', err)
		}
	}
)
/* user login */
export const userLoginRequest = createAsyncThunk<AxiosResponse<any, any>, FormLoginData>(
	'user/userLoginRequest',
	async ({email, password}: FormLoginData, { rejectWithValue}) => {
		const authString = `${email}:${password}`
		try {
			const loginRes: AxiosResponse = await basicRequest.post(
			// const loginRes: AxiosResponse = await request.post(
				`/api/v1/auth/login`,
				{},
				{
					headers: {
						Authorization: `Basic ${base64.encode(authString)}`
					},
				},
			);
			// console.log('loginRes: ', loginRes)
			return loginRes.data
		} catch (err:any) {
			// console.log('err from userLoginRequest: ', err)
			return ErrorHandle(err, rejectWithValue)
		}
	}
)

/*Dashboard*/
//get user account
export const getDashboard = async () => {
	return await authRequest.get('/api/v1/xs10/dashboard/')
}

/*Recipie page*/
export const getApiV1Xs10Recipes = createAsyncThunk(
	'recipiesLists/lists',
	async () => {
		return await authRequest.get('/api/v1/xs10/recipes')
		// return await request.get(`${BASE_URL}/api/v1/xs10/recipes`, {})
	}
)

/*Update the user tracking data
* 更新用户跟踪数据
* */
export async function updateUserTrackData(data: { value: string }) {
	return await authRequest.put('/api/v1/xs10/user_tracking', data)
}

/* How to get started */
export const getApiV1Xs10PreparationUnits = createAsyncThunk(
	'preparationUnits/lists',
	async () => {
		return await authRequest.get('/api/v1/xs10/preparation_units')
		// return await request.get(`${BASE_URL}/api/v1/xs10/preparation_units`, {})
	}
)

/* Guess in */
export const getFreeVideo = async () => {
	// setTimeout(async () => {
		return await authRequest.get('/api/v1/xs10/workouts/free')
	// }, 5000)
}

/*
* MyAccount Change account data
* */
export async function getYourTimezone(time: string):Promise<AxiosResponse<any, any>> {
	return await authRequest.get(`/api/v1/inf/translation/updatessince/${time}`)
}

export const userTimezones = createAsyncThunk<AxiosResponse<any, any>>(
	'user/userTimezones',
	getTimezones
)

export async function getTimezones(): Promise<AxiosResponse<any, any>> {
	return await authRequest.get('/api/v1/timezones')
}

export async function getAccount():Promise<AxiosResponse<any, any>> {
	return await authRequest.get('/api/v1/xs10/account')
}

//modify user account info
export async function modifyAccount(userInfos: MyAccount.UserInfoDataType["user_data"]) {
	return await authRequest.put('/api/v1/xs10/account', userInfos)
}

/*
* MyAccount Change account data
* */
export async function updateQuestionnaireResult(result: string) {
	return await authRequest.put('api/v1/xs10/questionnaire', {"diet": result});
}

/*changeDate page*/
export async function getDateFromDashboard() {
	return await authRequest.get('/api/v1/xs10/dashboard')
}

export async function reviseSubscriptionDate(data: { subscription_date: string }) {
	return await authRequest.put('/api/v1/xs10/subscription_date', data)
}

/*10 weeks plan*/
export async function getApiV1Xs10Weeks() {
	return await authRequest.get('/api/v1/xs10/weeks')
	// return await request.get<Program.WeekType[]>(`${BASE_URL}/api/v1/xs10/weeks`)
}

/*10 weeks plan*/

export const getApiV1Xs10WeekStore = createAsyncThunk(
	'weekLists/lists',
	async (params:{id:string}) => {
		const res = await authRequest.get<Program.WeekDetailsType[]>('/api/v1/xs10/week', {params})
		// console.log('res: ', res)
		if (res.data && res.data?.length){
			res.data.sort((a, b) => {
				const start = (/[0-9]/.exec(a.name) as RegExpExecArray)||[];
				const end = (/[0-9]/.exec(b.name) as RegExpExecArray)||[];
				return Number(start[0]) - Number(end[0])
			})
		}

		return {
			[params.id]:res.data
		}
	}
)

export async function getApiV1Xs10Units(params:{id:string,week:string}){
	return await authRequest.get('/api/v1/xs10/units', {params})
	// return await request.get<any[]>(`${BASE_URL}/api/v1/xs10/units`, params)
}

/* Exercise tracking */
export const getExerciseTrackingData = createAsyncThunk(
	'exercise_tracking/all',
	async () => {
		return await authRootRequest.get('/data/exercise_tracking_all_weeks.xml')
	}
)

/* get user before after image */
export const getBeforeAfterImg = async () => {
	return await authRequest.get('/api/v1/xs10/before_after_image')
}

interface uploadData {
	formData: object;
	type: string;
}
/* post before after image */
export const uploadBeforeAfterImg = async ({formData, type}:uploadData) => {
	return await authRequest.post(`/api/v1/xs10/user_file_upload?type=${type}`,
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data;',
					Accept: 'application/json'
				},
		},
	)
}

export async function getUserTracking() {
	return await authRequest.get<string>('/api/v1/xs10/user_tracking')
}
export async function putUserTracking(data:{value:string}) {
	return await authRequest.put('/api/v1/xs10/user_tracking', data)
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userLoginRequest } from "../../http/API";
import { typeUserState } from "../../types/RootData";
import type { RootState } from "../../index";

interface httpState {
	[key: string]: string;
}

const initState: httpState = {
	code: "",
	errorMessage: "",
};

const sliceHttp = createSlice({
	name: "reducer",
	initialState: initState,
	reducers: {
		setHttpState(state, action: any) {
			state.code = action.payload.code;
			state.errorMessage = action.payload.errorMessage;
		},
		clearHttpState(state) {
			state.code = "";
			state.errorMessage = "";
		},
	},
	extraReducers: (builder) => {
		// 	builder.addCase(
		// 		userLoginRequest.fulfilled,
		// 		(state: any, action: any) => {
		// 			if (action && action.payload) {
		// 				// console.log("action: ", action);
		// 				const { access_token: accessToken } = action.payload;
		// 				state.isSuccess = true;
		// 				state.token = accessToken;
		// 			}
		// 			// console.log("action in addCase: ", action);
		// 			return state;
		// 		}
		// 	);
	},
});
const { actions } = sliceHttp;
export const { setHttpState, clearHttpState } = actions;
export default sliceHttp;

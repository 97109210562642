import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getApiV1Xs10PreparationUnits} from "../http/API";
import { SliceActionType } from "../types/Store";
import { PreparationUnit, PreparationUnitsList } from '../types/preparationUnits';

const initialState: PreparationUnitsList = {
	list: [],
}
export const ListsSlice = createSlice({
	name: 'preparationUnitsList',
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<Array<PreparationUnit>>) => {
			state.list = [...action.payload]
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getApiV1Xs10PreparationUnits.fulfilled, (state, action:SliceActionType) => {
			state.list = [...action.payload.data as Array<PreparationUnit>];
		})
	}
})
export const {setList} = ListsSlice.actions
export default ListsSlice;
